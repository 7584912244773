/* loader */
.loader {
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
}
