.right-rounded {
  border-radius: 0 5px 5px 0;
}
.left-jumbo-image img {
  vertical-align: middle;
}

.teaser img {
  filter: grayscale(1);
  transition: filter 200ms linear;
}

.teaser:hover {
  img {
    filter: grayscale(0);
  }
}

.jumbo-title {
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 300px;
  background: linear-gradient(#00000000, black);
}
.full-episode {
  font-size: 4rem;
}
.episode-info {
  position: absolute;
  bottom: 0;
}

.hit {
  display: flex;
}

.hit-content {
  flex-grow: 1;
}
.ais-Hits {
  position: absolute;
  z-index: 999;
  overflow: auto;
  max-height: 700px;
  width: 96%;
  left: 2%;
  margin-top: -1.5em;
}
.ais-SearchBox {
  button {
    background: none;
    border: none;
  }
}

button.ais-SearchBox-reset {
  position: absolute;
  top: 0.75em;
  right: 1em;
  width: 2.5em;
  height: 2.5em;
  cursor: pointer;
}

.ais-SearchBox-input::-webkit-search-decoration,
.ais-SearchBox-input::-webkit-search-cancel-button,
.ais-SearchBox-input::-webkit-search-results-button,
.ais-SearchBox-input::-webkit-search-results-decoration {
  display: none;
}

.navbar-item svg {
  fill: #f2f2f2;
  width: 100px;
  height: 100px;
  transition: fill 200ms linear;
}

.navbar-item svg:hover {
  fill: #add8bf;
}

button.navbar-item {
  background: none;
  border: none;
  cursor: pointer;
}

.ais-Highlight-highlighted {
  background: #c6d963;
  color: #222;
}
.hit-content {
  transition: background 200ms linear;
}
.hit-content:hover {
  background: #343d3d;
}

.error-logo {
  margin-top: -5em;
}
@media only screen and (max-width: 768px) {
  .teaser img {
    min-width: 100%;
  }
}

.loader-blocks {
  fill: #f2f2f2;
}
.colossus-loader-letter {
  fill: #f2f2f200;
}
.colSVG-1 {
  animation: fadein 2s ease 0s 1 forwards;
}
.colSVG-2 {
  animation: fadein 2s ease 0.25s 1 forwards;
}
.colSVG-3 {
  animation: fadein 2s ease 0.5s 1 forwards;
}
.colSVG-4 {
  animation: fadein 2s ease 0.75s 1 forwards;
}
.colSVG-5 {
  animation: fadein 2s ease 1.25s 1 forwards;
}
.colSVG-6 {
  animation: fadein 2s ease 1.5s 1 forwards;
}
.colSVG-7 {
  animation: fadein 2s ease 1.75s 1 forwards;
}
.colSVG-8 {
  animation: fadein 2s ease 2s 1 forwards;
}

@keyframes fadein {
  0% {
    opacity: 0;
    fill: #f2f2f200;
  }
  100% {
    opacity: 1;
    fill: #f2f2f2;
  }
}
